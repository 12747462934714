<app-uni-dialog title="Print/Email Invoice" [parent]="this" [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"></app-uni-dialog>

<ng-template #contentTemplate>
  <div uni-flex-row>
    <mat-form-field style="width: 300px">
      <input matInput placeholder="Shipping Name" [ngModel]="joHeader.shippingName" readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 110px">
      <input matInput placeholder="Invoice #" [ngModel]="invoiceHeader.id" readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 160px">
      <input matInput placeholder="Status" [ngModel]="invoiceHeader.invoiceStatus.name" readonly="readonly" />
    </mat-form-field>
  </div>

  <div uni-flex-row class="mt-12" >
    <mat-form-field style="width: 300px">
      <input matInput placeholder="Billing Name" [ngModel]="joHeader.billingName" readonly="readonly" />
    </mat-form-field>
    <mat-form-field style="width: 300px">
      <input matInput placeholder="Invoice Email" [(ngModel)]="emailAddress" name="emailAddress" />
    </mat-form-field>
  </div>

  <div>
    <span>{{emailStatus}}</span>
  </div>

  <!-- <div *ngIf="isPrinting && canPost()">
    If the Invoice was <b>successfully printed</b>, it can be posted to the accounting system.
  </div> -->
  <div *ngIf="isManifested && canPost()">
    This invoice is on a manifest. It can be posted to the accounting system.
  </div>
  <!-- <div *ngIf="isManifested">
    This invoice is on a manifest. It cannot be posted to the accounting system independently.
  </div> -->
  <div *ngIf="isPosted()">
    This invoice has been sent to the accounting system. 
    <a style="color: blue" href="{{ getQuickbooksLink() }}" target="_blank">See invoice {{ invoiceHeader.id }} in Quickbooks</a>
  </div>
</ng-template>

<ng-template #footerTemplate>

  <div>
    <button *ngIf="canPreview" mat-raised-button (click)="showPreview()">Preview</button>
    <button mat-raised-button (click)="printDocument()">Print</button>
    <button *ngIf="!isManifested" [disabled]="!canSendEmail()" mat-raised-button
      (click)="sendEmail()">Email</button>
    <button *ngIf="canPost()" mat-raised-button (click)="postInvoiceToGL()" title="Post to accounting system">Post Invoice</button>
    <button mat-raised-button (click)="close()">Close</button>
  </div>
  <!-- <div *ngIf="isPrinting">
    <button mat-raised-button *ngIf="canPost()" (click)="postInvoiceToGL()">Post Invoice</button>
    <button mat-raised-button (click)="isPrinting = false">Cancel</button>
  </div> -->

</ng-template>
